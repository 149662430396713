body {
  background: #111;
  color     : #ddd;
  margin    : 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing : antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.wrapper {
  padding-top: 5rem;
  margin     : 0 auto;
  width      : 600px;
}

.rm {
  background-color: #f44;
  border-radius   : 50%;
  color           : #fff;
  border          : none;
}

.done {
  text-decoration: line-through;
}

.lds-dual-ring {
  background: transparent;
  display  : inline-block;
  width    : 64px;
  height   : 64px;
}

.lds-dual-ring:after {
  content      : ' ';
  display      : block;
  width        : 46px;
  height       : 46px;
  margin       : 1px;
  border-radius: 50%;
  border       : 5px solid #33f;
  border-color : #33f transparent #33f transparent;
  animation    : lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}