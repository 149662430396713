.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  padding-top: 5rem;
}

.modal-body {
  padding: 2rem;
  width: 400px;
  border-radius: 5px;
  background: #111;
  height: 200px;
}
